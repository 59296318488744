import React from 'react';

import Button from '../../UI/Button/Button';
import classes from './OrderSummary.module.css';

const OrderSummary = (props) => {

    const ingredientSummary= []
    for (const ingredient in props.ingredients) {
        ingredientSummary.push(
                <li key= {Date.now() + Math.random()}>
                    <span style={{textTransform:'capitalize'}}>{ingredient}
                    </span> : {props.ingredients[ingredient]}
                </li>)
    }

    return (
        <React.Fragment>
            <h3> Your Order</h3>
            <p> Your ordered your burger with the following ingredients :</p>
            <ul>
                {ingredientSummary}
            </ul>
            <p className={classes.TotalPrice}>Total : {props.totalPrice.toFixed(2)}€</p>
            <p> Proceed to CheckOut ?</p>
            <Button btnType="Danger" clicked={props.purchaseCancel}> Cancel</Button>
            <Button btnType="Success" clicked={props.purchaseContinue}>Order</Button>
        </React.Fragment>


    );
};

export default OrderSummary;
