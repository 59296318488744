import React from 'react';

import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.css';
import Backdrop from '../../UI/Backdrop/Backdrop';

const SideDrawer = (props) => {
    let drawerClasses
    if (props.open) { drawerClasses = [classes.SideDrawer].concat([classes.Open]).join(' ')}
    else { drawerClasses = [classes.SideDrawer].concat([classes.Close]).join(' ')}
    return (
        <React.Fragment>
            <Backdrop show={props.open} clicked={props.sideDrawerClosed} />
            <div className={drawerClasses} onClick={props.sideDrawerClosed}>
                <div className={classes.Logo}>
                    <Logo />
                </div>
                <nav>
                    <NavigationItems
                        isLoggedIn={props.isLoggedIn}
                    />
                </nav>
            </div>
        </React.Fragment>
    );
};

export default SideDrawer;
