import * as actionsTypes  from "../actionTypes";
import axiosnocrentials from '../../../databaseConnexion/withOutCredentials/withOutCredentials';
import {checkAuthTimeOut} from '../index';

export const newUserStart = () => {
    return {
        type: actionsTypes.NEW_USER_START
    }
}

export const newUserSuccess = (authData, token, refreshToken,tokenExpireTime, refreshTokenExpireTime) => {
    return {
        type: actionsTypes.NEW_USER_SUCCESS,
        authData: authData,
        token: token,
        refreshtoken: refreshToken,
        tokenExpireTime: tokenExpireTime,
        refreshTokenExpireTime: refreshTokenExpireTime
    }
}

export const newUserFailure = (error, message=null) => {
    return {
        type: actionsTypes.NEW_USER_FAILURE,
        error: error,
        message: message
    }
}

export const newUser = (email, password) => {

    const authCrebdentials ={
        email: email,
        password: password
    };

    let url;
    if (process.env.NODE_ENV !== 'production') {url= '/api/newuser'}
        else {url = process.env.REACT_APP_APIHOST + '/newuser'}


    return dispatch => {
        dispatch(newUserStart());
        axiosnocrentials.post(url, authCrebdentials)
            .then((res) => {
                if (res.data.fnc_new_user.success) {
                        dispatch(newUserSuccess(    res.data.fnc_new_user,
                                                    res.data.token,
                                                    res.data.refreshtoken,
                                                    res.data.tokenExpireTime,
                                                    res.data.refreshTokenExpireTime,
                                                )
                                );
                        dispatch(checkAuthTimeOut(res.data.refreshTokenExpireTime));
                    } else {
                        console.log(res.data.fnc_new_user);
                        const message = res.data.fnc_new_user.error || 'An Error has ocuured'
                        dispatch(newUserFailure(res.data.fnc_new_user, message));
                    }

            })
            .catch((err) => {
                dispatch(newUserFailure(err));
                console.log(err)
            })
    }
}
