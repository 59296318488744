import * as actionTypes from '../../actions/actionTypes';
import { initialState } from './initialState';
import { addIngredient, removeIngredient, resetIngredient, updateUserModified,
        initIngredientError, initIngredientSuccess } from './functions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_INGREDIENT:return addIngredient( state, action );
        case actionTypes.REMOVE_INGREDIENT: return removeIngredient( state, action );
        case actionTypes.SET_INGREDIENTS: return initIngredientSuccess( state, action );
        case actionTypes.RESET_INGREDIENTS: return resetIngredient( state );
        case actionTypes.INIT_INGREDIENTS_ERROR: return initIngredientError(state, action);
        case actionTypes.UPDATE_USER_MODIFIED: return updateUserModified(state);
        default:
            return state;
    }
}

export default reducer;
