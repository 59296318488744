export const initialState = {
    loggedIn : false,
    token:null,
    error: false,
    errorMessage:null,
    refreshtoken: null,
    user: null,
    loading: false,
    tokenrefreshtime : null,
    refreshtokenrefreshtime : null,
    timeOutId: null
}
