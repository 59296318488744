import React from 'react';
// eslint-disable-next-line
import PropTypes from 'prop-types';

import classes from './BuildControl.module.css'

const BuildControl = props => {
    return (
        <div className={classes.BuildControl}>
           <div className={classes.Label}>{props.label}</div>
           <button  className={classes.Less}
                    onClick={props.removeIngredient}
                    disabled={props.disabled}>
                    Less
            </button>
           <button  className={classes.More}
                    onClick={props.addIngredient}>
                    More
            </button>
        </div>
    );
};

BuildControl.propTypes = {

};

export default BuildControl;
