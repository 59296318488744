import instanceRefreshToken from '../refreshToken/refreshToken'

export const refreshToken = async () => {

    let tokens
    try {
        tokens = await instanceRefreshToken.post('/api/refreshtoken',{});
    } catch (error) {
        console.log(error.response);
    }

    return tokens;
}

export const saveTokens = (token, refreshToken, user,
                            tokenDuration,refreshTokenDuration) => {
                    
    const decoy1 = {
        type: 'decoy',    
        value: require('crypto').randomBytes(128).toString('hex')
    };
    const decoy2 = {
        type: 'decoy',    
        value: require('crypto').randomBytes(128).toString('hex')
    };
    const decoy3 = {
        type: 'decoy',    
        value: require('crypto').randomBytes(128).toString('hex')
    };

    const expireDate = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + parseInt(refreshTokenDuration.slice(0,-1)));

    const obj = {
        token: token,
        refreshtoken: refreshToken,
        user: user,
        expat: expireDate,
        tokenduration: tokenDuration,
        refreshtokenduration: refreshTokenDuration
    }

    const objEncoded = btoa(JSON.stringify(obj));
    const decoy1Encoded = btoa(JSON.stringify(decoy1));
    const decoy2Encoded = btoa(JSON.stringify(decoy2));
    const decoy3Encoded = btoa(JSON.stringify(decoy3));

    // const objDecoded = JSON.parse(atob(objEncoded))

    localStorage.setItem("__Zat", objEncoded);
    localStorage.setItem("__JHY", decoy1Encoded);
    localStorage.setItem("LNgg__", decoy2Encoded);
    localStorage.setItem("hhGGFRD__", decoy3Encoded);
}

export const removeTokens = () => {

    localStorage.removeItem("__Zat");
    localStorage.removeItem("__JHY");
    localStorage.removeItem("LNgg__");
    localStorage.removeItem("hhGGFRD__");
}
