export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const RESET_INGREDIENTS = 'RESET_INGREDIENTS';
export const INIT_INGREDIENTS_ERROR = 'INIT_INGREDIENTS_ERROR';
export const UPDATE_USER_MODIFIED = 'UPDATE_USER_MODIFIED';

export const PURCHASE_BURGER_SUCCESS = 'PURCHASE_BURGER_SUCCESS';
export const PURCHASE_BURGER_FAILURE = 'PURCHASE_BURGER_FAILURE';

export const PURCHASE_BURGER_START = 'PURCHASE_BURGER_START';
export const PURCHASE_BURGER_INIT = 'PURCHASE_BURGER_INIT';

export const SHOW_ALL_ORDERS_START = 'SHOW_ALL_ORDERS_START';
export const SHOW_ALL_ORDERS_SUCCESS = 'SHOW_ALL_ORDERS_SUCCESS';
export const SHOW_ALL_ORDERS_FAILURE = 'SHOW_ALL_ORDERS_FAILURE';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_RESET = 'AUTH_RESET';

export const NEW_USER_START = 'NEW_USER_START';
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAILURE = 'NEW_USER_FAILURE';
export const NEW_USER_RESET = 'NEW_USER_RESET';

export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';
export const UPDATE_TIMEOUTID = 'UPDATE_TIMEOUTID';

export const LOG_OUT = 'LOG_OUT';
