import * as actionTypes from "../actionTypes";
import axiosnocredentials from '../../../databaseConnexion/withOutCredentials/withOutCredentials';


export const addIngredient = (ingredientName) => {
    return {
        type: actionTypes.ADD_INGREDIENT,
        ingredientName: ingredientName
    }
}

export const updateUserModified = () => {
    return {
        type: actionTypes.UPDATE_USER_MODIFIED
    }
}

export const removeIngredient = (ingredientName) => {
    return {
        type: actionTypes.REMOVE_INGREDIENT,
        ingredientName: ingredientName
    }
}

export const resetIngredient = () => {
    return {
        type: actionTypes.RESET_INGREDIENTS
    }
}

const extractIngredients = dBaseRes => {
    const rawIngredients = dBaseRes.ingredients;
    const ingredients= {}
    for (const rawIngredient of rawIngredients) {
        ingredients[rawIngredient['ingredient_name'].toLowerCase()] = 0;
    }
    return ingredients;
}

export const setIngredients = (init) => {
    const ingredients = extractIngredients(init);
    return {
        type: actionTypes.SET_INGREDIENTS,
        ingredients: ingredients,
        price: init.base_price,
        rawIngredients: init.ingredients
    }
};

export const initIngredientsError = (params) => {
    return {
        type: actionTypes.INIT_INGREDIENTS_ERROR
    }
}

export const initIngredient = () => {
    let url;
    if (process.env.NODE_ENV !== 'production') {url= 'api/init'}
        else {url = process.env.REACT_APP_APIHOST + '/init'}

    return dispatch => {
        axiosnocredentials.get(url)
            .then ((res) => {
                dispatch(setIngredients(res.data.fnc_get_init));
            } )
            .catch ((err) => {
                dispatch(initIngredientsError());
                console.log(err)
            });
    }
}
