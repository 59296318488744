import React from 'react';
// eslint-disable-next-line
import PropTypes from 'prop-types';

import classes from './Burger.module.css'
import BurgerIngredient from './BurgerIngredients/BurgerIngredient'

const Burger = ({ingredients}) => {

    const burgerIgredientsMaker = (ingredients) => {
        const ingredientArray = []
        for (const ingredient in ingredients) {
            const nbIngredient = parseInt(ingredients[ingredient]);
            for (let idx = 0; idx < nbIngredient; idx++) {
                ingredientArray.push(
                            <BurgerIngredient
                            key={ingredientArray.length + 2}
                            type={ingredient} />);
            }
        }
        if (ingredientArray.length === 0) { return <p> Please Start adding ingredients</p>}
        return ingredientArray;
    }

    return (
        <div className={classes.Burger} >
            <BurgerIngredient type="bread-top" />
            {burgerIgredientsMaker(ingredients)}
            <BurgerIngredient type="bread-bottom" />
        </div>
    );
};

// eslint-disable-next-line
Burger.propTypes = {

};

export default Burger;
