import axios from 'axios'
import store from '../../store/store/store';
import { authLogOut, updateCredentials, checkAuthTimeOut, resetIngredient } from '../../store/actions/index';

const instance = axios.create({
    headers: {
        "Content-Type": "application/json",
    },

})


instance.interceptors.request.use((config) => {
    const refreshToken = store.getState().auth.refreshtoken;
    config.headers.Authorization = `bearer ${refreshToken}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(
    (response) => {
        const {dispatch} = store;
        dispatch( updateCredentials(response.data));
        dispatch( checkAuthTimeOut(response.data.refreshtokenexpiretime));
        return response
    },
    async (err) => {
        const error = err.response;
        if (error.status===401) {
            const {dispatch} = store;
            dispatch(authLogOut());
            dispatch(resetIngredient());
            return Promise.reject(err);
        } else {
            return Promise.reject(err);
        }
});

export default instance;
