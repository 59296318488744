import * as actionTypes from '../../actions/actionTypes';
import { initialState } from './initialState';
import { updateObject } from '../../utilities/functions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_ALL_ORDERS_START:
            return updateObject(state, { loading:true, error: null, orders : []});
        case actionTypes.SHOW_ALL_ORDERS_SUCCESS:
            return updateObject(state, { loading:false,orders: action.orders});
        case actionTypes.SHOW_ALL_ORDERS_FAILURE:
            return updateObject(state, { loading:false,error: action.error, orders : []});
        default:
            return state;
    }

}

export default reducer;
