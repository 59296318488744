import * as actionsTypes  from "../actionTypes";
import axios from '../../../databaseConnexion/withCredentials/withCredentials';

export const showAllOrdersSuccess = (orders) => {
    return {
        type: actionsTypes.SHOW_ALL_ORDERS_SUCCESS,
        orders: orders
    }
}

export const showAllOrdersFailure = (error) => {
    return {
        type: actionsTypes.SHOW_ALL_ORDERS_FAILURE,
        error: error
    }
}

export const showAllOrdersStart = () => {
    return {
        type: actionsTypes.SHOW_ALL_ORDERS_START
    }
}

export const showAllOrders =  (orderData) => {

    let url;
    if (process.env.NODE_ENV !== 'production') {url= '/api/allorders'}
    else {url = process.env.REACT_APP_APIHOST + '/allorders'}

    return dispatch => {
        dispatch(showAllOrdersStart());

        axios.post(url, orderData)
            .then((res) => {
                dispatch(showAllOrdersSuccess(res.data.fnc_get_allorders.orders));
            })
            .catch((error) => {
                dispatch(showAllOrdersFailure(error.message));
            })
    }
}
