import * as actionsTypes  from "../actionTypes";
import axioscredentials from '../../../databaseConnexion/withCredentials/withCredentials';

export const purchaseBurgerSuccess = (id, orderData) => {
    return {
        type: actionsTypes.PURCHASE_BURGER_SUCCESS,
        orderId: id,
        orderData: orderData
    }
}

export const purchaseBurgerFailure = (error) => {
    return {
        type: actionsTypes.PURCHASE_BURGER_FAILURE,
        error: error
    }
}

export const purchaseBurgerStart = (error) => {
    return {
        type: actionsTypes.PURCHASE_BURGER_START
    }
}

export const burgerPurchase = (orderData) => {
    let url;
    if (process.env.NODE_ENV !== 'production') {url= '/api/neworder'}
    else {url = process.env.REACT_APP_APIHOST + '/neworder'}

    return dispatch => {
        dispatch(purchaseBurgerStart());
        axioscredentials.post(url, orderData)
            .then((res) => {
                dispatch(purchaseBurgerSuccess(res.data.fnc_new_order.order, orderData))
            })
            .catch((err) => {
                dispatch(purchaseBurgerFailure(err));
                console.log(err)
            })
    }
}

export const purchaseBurgerInit = () => {
    return {
        type: actionsTypes.PURCHASE_BURGER_INIT
    }
}
