import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utilities/functions';

export const getIngredientPrice = (rawIngredients, ingredient) => {
    const filterredIngredient = rawIngredients.filter((item) => {
        return item.ingredient_name.toUpperCase() === ingredient.toUpperCase()
    })
    return filterredIngredient[0].ingredient_price;
}

export const updatePurchasableState = (ingredients, action) => {
    let sum =0;
    for (const ingredient in ingredients) {
        sum += ingredients[ingredient]
    }

    if (action === actionTypes.ADD_INGREDIENT && sum === 0) {
        return true;
    }

    if (action === actionTypes.REMOVE_INGREDIENT && sum === 1) {
        return false;
    }

    return sum > 0 ;
};


export const addIngredient = ( state, action ) => {
    const updatedIngredient = { [action.ingredientName]: state.ingredients[action.ingredientName] + 1 }
    const updatedIngredients = updateObject( state.ingredients, updatedIngredient );
    const updatedState = {
        ingredients: updatedIngredients,
        totalPrice: state.totalPrice + getIngredientPrice(state.rawIngredients,action.ingredientName),
        purchasable:updatePurchasableState(state.ingredients, actionTypes.ADD_INGREDIENT),
        userModified: true
    }
    return updateObject( state, updatedState );
};

export const removeIngredient = ( state, action ) => {
    const updatedIngredient = { [action.ingredientName]: state.ingredients[action.ingredientName] - 1 }
    const updatedIngredients = updateObject( state.ingredients, updatedIngredient );
    const updatedState = {
        ingredients: updatedIngredients,
        totalPrice: state.totalPrice - getIngredientPrice(state.rawIngredients,action.ingredientName),
        purchasable:updatePurchasableState(state.ingredients, actionTypes.REMOVE_INGREDIENT),
        userModified: true
    }
    return updateObject( state, updatedState );
};

export const resetIngredient = ( state) => {
    const updatedState = {
        ingredients: null,
        totalPrice: 0,
        purchasable:false,
        userModified: false
    }
    return updateObject( state, updatedState );
};

export const initIngredientError = ( state, action ) => {
    return updateObject( state, { error: true } );
};

export const updateUserModified = ( state) => {
    return updateObject( state, { userModified: false , purchasable: false} );
};

export const initIngredientSuccess = ( state, action ) => {
    return updateObject( state, { ingredients:action.ingredients, totalPrice: action.price,
                                    rawIngredients: action.rawIngredients,error: false
                                }
                        );
};
