import * as actionTypes from '../../actions/actionTypes';
import { initialState } from './initialState';
import { authFailure, authStart, authSuccess, authReset,
                logOut, updateCredentials, updateTimeOutId } from './functions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SUCCESS:return authSuccess( state, action );
        case actionTypes.AUTH_FAILURE: return authFailure( state, action );
        case actionTypes.AUTH_RESET: return authReset( state);
        case actionTypes.NEW_USER_SUCCESS: return authSuccess( state, action );
        case actionTypes.NEW_USER_FAILURE: return authFailure(state, action);
        case actionTypes.AUTH_START: return authStart (state);
        case actionTypes.NEW_USER_START: return authStart (state);
        case actionTypes.LOG_OUT: return logOut(state);
        case actionTypes.UPDATE_CREDENTIALS  : return updateCredentials(state, action);
        case actionTypes.UPDATE_TIMEOUTID  : return updateTimeOutId(state, action);
        default:
            return state;
    }
}

export default reducer;
