import React, { Component } from 'react';
import { connect} from 'react-redux';

import classes from './Layout.module.css';
import ToolBar from '../Navigation/ToolBar/ToolBar';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';


class Layout extends Component {

    state = {
        showSideDrawer: false
    }

    sideDrawerCloseHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState( (prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    render() {
        return (
            <React.Fragment>
                <ToolBar
                    isLoggedIn={this.props.loggedIn}
                    drawerToggleClicked={this.sideDrawerToggleHandler}/>
                <SideDrawer
                    isLoggedIn={this.props.loggedIn}
                    open={this.state.showSideDrawer}
                    sideDrawerClosed={this.sideDrawerCloseHandler} />
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn
    };
}

export default connect (mapStateToProps) (Layout);
