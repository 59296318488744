import React, { Component } from 'react';

import classes from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {

    shouldComponentUpdate(nextProps, nextState) {
         return nextProps.show !== this.props.show || nextProps.children !== this.props.children
    }

    render() {
        const classDiv = this.props.show ? classes.Modal : classes.ModalHide
        return (
            <React.Fragment>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed}/>
                <div className={classDiv}>
                   {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

export default Modal;
