import React from 'react';
import { connect } from 'react-redux';

import BuildControl from './BuildControl/BuildControl';
import classes from './BuildControls.module.css';

const BuildControls = (props) => {

    const allIngredients = []
    for (const ingredient of props.rawIngredients) {
        const label = `${ingredient.ingredient_name} (${ingredient.ingredient_price}€)`
        allIngredients.push({label: label, type:ingredient.ingredient_name.toLowerCase()})
    }

    return (
        <div className={classes.BuildControls}>
            <p className={classes.TotalPrice}> Total Price of your Burger : <strong>{props.totalPrice.toFixed(2)}€</strong></p>
            <div className={classes.AddIngredientsDiv}>
                {allIngredients.map((item) =>
                            <BuildControl
                                key={item.label}
                                label={item.label}
                                addIngredient={()=> props.addIngredient(item.type)}
                                removeIngredient={() => props.removeIngredient(item.type)}
                                disabled={props.disableInfo[item.type]}
                            />
                )}
            </div>
            <button
                className={classes.OrderButton}
                onClick={props.purchasing}
                disabled={!props.purchasableState}
                >
                {props.isLoggedIn ? "Check Out" : "Sign In"}
            </button>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        rawIngredients: state.burgerBuilder.rawIngredients,
        isLoggedIn : state.auth.loggedIn
    };
}


export default connect (mapStateToProps)(BuildControls);
