import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './components/Layout/Layout';
import BurgerBuilder from './containers/BurgerBuilder/BurgerBuilder';
import { checkAuthState } from './store/actions/index';
import asyncComponent from './components/asyncComponent/asyncComponent';

const AsyncCheckout = asyncComponent (() => {
    return import('./containers/CheckOut/CheckOut');
})

const AsyncOrders = asyncComponent (() => {
    return import('./containers/Orders/Orders');
})
const AsyncAuth = asyncComponent (() => {
    return import('./containers/Auth/Auth');
})

const AsyncNewUser = asyncComponent (() => {
    return import('./containers/NewUser/NewUser');
})

const AsyncLogOut = asyncComponent (() => {
    return import('./containers/LogOut/LogOut');
})


class App extends React.Component {

  componentDidMount() {
      this.props.onTryAutoSignUp();

  }

  render() {

    let allowedRoutes = (
                        <Routes>
                            <Route path="/" element={<BurgerBuilder/>} />
                            <Route path="/auth" element={<AsyncAuth/>} />
                            <Route path="/newuser" element={<AsyncNewUser />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>

    )

    if (this.props.isLoggedIn) {
      allowedRoutes = (
                      <Routes>
                          <Route path="/" element={<BurgerBuilder/>} />
                          <Route path="/orders" element={<AsyncOrders/>} />
                          <Route path="/logout" element={<AsyncLogOut/>} />
                          <Route path="/checkout/*" element={<AsyncCheckout />} />
                          <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
)
    }

    return (  <div>
                <Layout>
                    {allowedRoutes}
                </Layout>
              </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.auth.loggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      onTryAutoSignUp: () => dispatch(checkAuthState())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (App);
