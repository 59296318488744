import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const NavigationItems = (props) => {
    return (
        <ul className={classes.NavigationItems}>
            <NavigationItem link="/">
                Burger Builder
            </NavigationItem>
            {props.isLoggedIn ? <NavigationItem link="/orders">
                                    Orders
                                </NavigationItem>
                              : null
            }
            {props.isLoggedIn ? <NavigationItem
                                    link="/logout">LogOut
                                </NavigationItem>
                              : <NavigationItem
                                    link="/auth">Authentification
                                </NavigationItem>


            }

        </ul>
    );
};

export default NavigationItems;
