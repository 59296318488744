import React, { Component } from 'react';
import { connect } from 'react-redux';

import Burger from '../../components/Burger/Burger';
import BuildControls from '../../components/Burger/BuildControls/BuildControls';
import Modal from '../../components/UI/Modal/Modal';
import OrderSummary from '../../components/Burger/OrderSummary/OrderSummary';
import axios from '../../databaseConnexion/withCredentials/withCredentials';
import Spinner from '../../components/UI/Spinner/Spinner';
import withErrorHandler from '../../components/withErrorHandler/withErrorHandler';
import { withRouter } from '../../components/withRouter/withRouter';
import { addIngredient,removeIngredient, initIngredient } from "../../store/actions/index";
import { purchaseBurgerInit } from '../../store/actions/index';

class BurgerBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wantToPurchase: false
        }
    }

    componentDidMount () {

        if (!this.props.userModified) {
            this.props.onInitIngredient();
        }
    }

    wantToPurchaseHandler = () => {
        if (this.props.isLoggedIn) {
            this.setState({ wantToPurchase: true });
        } else {
            const navigate= this.props.navigate;
            navigate ("/auth");
        }

    }

    cancelWantToPurchaseHandler = () => {

        this.setState({ wantToPurchase: false });
    }

    purchaseContunueHandler = () => {
        this.props.onInitPurchase();
        const navigate= this.props.navigate;
        navigate ("/checkout");
    }


    render() {
        const disableInfo = {
            ...this.props.ingredients
        }
        for (const ingredient in disableInfo) {
                disableInfo[ingredient] = disableInfo[ingredient] <=0
        }
        let burger = this.props.error ? <p>Ingredients can't be loaded!</p> : <Spinner />;

        if (this.props.ingredients) {
            burger = (
                <React.Fragment>
                    <Burger ingredients = {this.props.ingredients}/>
                    <BuildControls
                        addIngredient={this.props.onIngredientAdded}
                        removeIngredient={this.props.onIngredientRemoved}
                        disableInfo={disableInfo}
                        purchasableState={this.props.purchasable}
                        totalPrice={this.props.totalPrice}
                        purchasing={this.wantToPurchaseHandler}
                    />
                </React.Fragment>
            )
        }
        let orderSummary =<OrderSummary
                                ingredients={this.props.ingredients}
                                purchaseContinue={this.purchaseContunueHandler}
                                purchaseCancel={this.cancelWantToPurchaseHandler}
                                totalPrice={this.props.totalPrice}
                            />

        return (

            <React.Fragment>
                <Modal show={this.state.wantToPurchase} modalClosed={this.cancelWantToPurchaseHandler}>
                {orderSummary}
                </Modal>
                {burger}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ingredients: state.burgerBuilder.ingredients,
        totalPrice: state.burgerBuilder.totalPrice,
        purchasable: state.burgerBuilder.purchasable,
        error: state.burgerBuilder.error,
        userModified: state.burgerBuilder.userModified,
        isLoggedIn : state.auth.loggedIn
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onIngredientAdded: (ingredientName) => dispatch(addIngredient( ingredientName)),
        onIngredientRemoved: (ingredientName) => dispatch(removeIngredient(ingredientName)),
        onInitIngredient: () => dispatch(initIngredient()),
        onInitPurchase: () => dispatch(purchaseBurgerInit())
    };
}

export default connect (mapStateToProps,mapDispatchToProps ) (withRouter(withErrorHandler(BurgerBuilder, axios)));
