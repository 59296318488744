import * as actionsTypes  from "../actionTypes";
import axioscredentials from '../../../databaseConnexion/withCredentials/withCredentials';
import axiosnocredentials from '../../../databaseConnexion/withOutCredentials/withOutCredentials';
import bcrypt from 'bcryptjs';
import store from '../../store/store';


export const authStart = () => {
    return {
        type: actionsTypes.AUTH_START
    }
}

export const authReset = () => {
    return {
        type: actionsTypes.AUTH_RESET
    }
}

export const authLogOut = () => {
    return {
        type: actionsTypes.LOG_OUT
    }
}

export const checkAuthTimeOut = (timeOut) => {

    const timeOutId = store.getState().auth.timeOutId;
    if (timeOutId) { clearTimeout(timeOutId) }
    return dispatch => {
        const timeOutId = setTimeout(() => {
            dispatch(authLogOut());
        },  1000*  parseInt(timeOut.slice(0,-1)) );
        dispatch(updateTimeOutId(timeOutId));
    }
};

export const updateCredentials = (credentials) => {
    return {
        type: actionsTypes.UPDATE_CREDENTIALS,
        credentials: credentials
    }
};

export const updateTimeOutId = (timeOutId) => {
    return {
        type: actionsTypes.UPDATE_TIMEOUTID,
        timeoutid: timeOutId
    }
};



export const authSuccess = (authData, token, refreshToken, tokenExpireTime, refreshTokenExpireTime) => {
    return {
        type: actionsTypes.AUTH_SUCCESS,
        authData: authData,
        token: token,
        refreshtoken : refreshToken,
        tokenExpireTime: tokenExpireTime,
        refreshTokenExpireTime: refreshTokenExpireTime
    }
}

export const authFailure = (error, message=null) => {
    return {
        type: actionsTypes.AUTH_FAILURE,
        error: error,
        message: message
    }
}

export const auth = (email, password) => {
    const authCrebdentials ={
        email: email
    };
    return dispatch => {
        let url;
        if (process.env.NODE_ENV !== 'production') {url= 'api/login'}
        else {url = process.env.REACT_APP_APIHOST + '/login'}

        dispatch(authStart());
        axiosnocredentials.post(url, authCrebdentials)
            .then((res) => {
                if (res.data.fnc_log_in.success) {
                    if (bcrypt.compareSync( password + email, res.data.fnc_log_in.password)){
                        dispatch(authSuccess(   res.data.fnc_log_in,
                                                res.data.token,
                                                res.data.refreshtoken,
                                                res.data.tokenExpireTime,
                                                res.data.refreshTokenExpireTime,
                                            )
                        );

                        if (process.env.NODE_ENV !== 'production') {url= '/api/loginsuccessful'}
                        else {url = process.env.REACT_APP_APIHOST + '/loginsuccessful'}
                        axioscredentials.post(url, authCrebdentials);
                        dispatch(checkAuthTimeOut(res.data.refreshTokenExpireTime));

                    } else {
                        const message='email/password not found';
                        dispatch(authFailure(res.data.fnc_log_in, message));
                    }

                } else {
                    console.log(res.data.fnc_log_in);
                    const message = res.data.fnc_log_in.ERROR || 'An Error has ocuured'
                    dispatch(authFailure(res.data.fnc_log_in, message));
                }
            })
            .catch((err) => {
                dispatch(authFailure(err));
                console.log(err)
            })
    }
}

export const checkAuthState = () => {

    return dispatch => {

        const savedStorage = localStorage.getItem('__Zat');
        if (!savedStorage){
                dispatch(authLogOut())
        } else {

            const tokenDecoded = JSON.parse(atob(savedStorage));
            const {user, token,refreshtoken } = tokenDecoded;
            const {tokenduration, refreshtokenduration,expat } = tokenDecoded;
            const expAt = new Date(expat);
            if (expAt > new Date()){
                const remainingTime= (expAt - new Date())/1000;
                dispatch(authSuccess( user, token, refreshtoken,tokenduration,refreshtokenduration));
                dispatch(checkAuthTimeOut(remainingTime + 's'));
            } else {
                dispatch(authLogOut())
            }
        }
    }
};
