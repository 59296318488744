import * as actionTypes from '../../actions/actionTypes';
import { initialState } from './initialState';
import { updateObject } from '../../utilities/functions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PURCHASE_BURGER_INIT:
            return updateObject(state, { purchased: false});
        case actionTypes.PURCHASE_BURGER_START:
            return updateObject(state, { loading:true});
        case actionTypes.PURCHASE_BURGER_SUCCESS:
            const newOrder = updateObject(action.orderData, {id: action.orderId});
            return  updateObject(state, { loading:false,purchased: true,orders:state.orders.concat(newOrder) });
        case actionTypes.PURCHASE_BURGER_FAILURE:
            return updateObject(state, { loading:false,error: action.error});
        default:
            return state;
    }
}

export default reducer;
