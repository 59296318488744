import burgerBuilderReducer from '../reducers/burgerBuilder/burgerBuilder';
import orderReducer from '../reducers/order/order';
import allOrdersReducer from '../reducers/allOrders/allOrders';
import authReducer from '../reducers/auth/auth';
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

const composeEnhancers = process.env.REACT_APP_MODE === 'DEV' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__:null || compose;


const rootReducer=combineReducers ({
    burgerBuilder: burgerBuilderReducer,
    order: orderReducer,
    allOrders: allOrdersReducer,
    auth: authReducer
})

const store = createStore(rootReducer,composeEnhancers (applyMiddleware (thunk)));

export default store;
