import axios from 'axios'
import { refreshToken } from '../utils/function';
import store from '../../store/store/store';

const instance = axios.create({
    headers: {
        "Content-Type": "application/json",
    },

})

instance.interceptors.request.use((config) => {
    const token = store.getState().auth.token;
    config.headers.Authorization = `bearer ${token}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(
    (response) => {return response },
    async (err) => {

        const error = err.response;
        if (error.status===401 && error.config && !error.config.__isRetryRequest) {
            const newTokens = await refreshToken();
            if ( newTokens && newTokens.status === 200){
                    const token = store.getState().auth.token;
                    error.config.headers.Authorization = `bearer ${token}`;
                    return instance(error.config);
                }
    }
    return Promise.reject(err);

});

export default instance;
