import { saveTokens, removeTokens } from '../../../databaseConnexion/utils/function';

export const authSuccess = ( state, action ) => {

    const user = {
        email : action.authData.email,
        lastlogin : action.authData.lastlogin,
        userId : action.authData.user_id
    }

    saveTokens(action.token, action.refreshtoken, user,
                    action.tokenExpireTime, action.refreshTokenExpireTime);

    const updatedState = {
        ...state,
        user: user,
        loggedIn : true,
        token:action.token,
        error: false,
        errorMessage:null,
        loading: false,
        refreshtoken : action.refreshtoken,
        tokenrefreshtime : action.tokenExpireTime,
        refreshtokenrefreshtime : action.refreshTokenExpireTime
    }

    return updatedState;
};

export const updateCredentials = ( state, action ) => {


    saveTokens(action.credentials.token, action.credentials.refreshtoken,
                state.user, action.credentials.tokenexpiretime,
                action.credentials.refreshtokenexpiretime  );

    const updatedState = {
        ...state,
        token:action.credentials.token,
        refreshtoken : action.credentials.refreshtoken,
        tokenrefreshtime : action.credentials.tokenexpiretime,
        refreshtokenrefreshtime : action.credentials.refreshtokenexpiretime
    }

    return updatedState;
};

export const updateTimeOutId = ( state, action ) => {

    const updatedState = {
        ...state,
        timeOutId :action.timeoutid,
    }

    return updatedState;
};

export const authFailure = ( state, action ) => {

    removeTokens();
    const errorMessage = action.message ?  action.message : action.error
    const updatedState = {
        ...state,
        loggedIn : false,
        token:null,
        refreshtoken: null,
        user: null,
        loading: false,
        error: true,
        errorMessage: errorMessage,
        tokenrefreshtime : null,
        refreshtokenrefreshtime : null,
        timeOutId: null
    }

    return updatedState;
};

export const authStart = (state) => {

    removeTokens();

    const updatedState = {
        ...state,
        loggedIn : false,
        loading: true,
        error: false,
        errorMessage:null
    }

    return updatedState;
};

export const authReset = (state) => {

    const updatedState = {
        ...state,
        error: false,
        errorMessage:null
    }

    return updatedState;
};

export const logOut = (state) => {

    removeTokens();

    if (state.timeOutId) { clearTimeout(state.timeOutId) }

    const updatedState = {
        ...state,
        user: null,
        loggedIn : false,
        token:null,
        error: false,
        errorMessage:null,
        loading: false,
        refreshtoken : null,
        tokenrefreshtime : null,
        refreshtokenrefreshtime : null,
        timeOutId: null
    }

    return updatedState;
};
